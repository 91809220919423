@font-face {
  font-family: "Graffiti";
  src: local("FatWandals_PERSONAL"),
  url("./fonts/FatWandals_PERSONAL.ttf") format("truetype");
}

a {
  color: rgb(226, 223, 176);
}

body {
  margin: 0;
  background-color:rgb(52, 71, 49);
  font-family: 'Playfair Display', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(226, 223, 176)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.custom-toggler.navbar-toggler {
  border-color: rgb(226, 223, 176);
}

.content {
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 100px;
  /* padding-left: 10%;
  padding-right: 10%; */
}

.content h1 {
  color: rgb(226, 223, 176);
  font-weight: 600;
  /* font-style: italic; */
  /* font-size: 50px; */
  font-size: 5rem;
  /* padding-left: 10%; */
}

.content h2 {
  color: rgb(226, 223, 176);
  font-weight: 100;
  /* font-style: italic; */
  /* font-size: 50px; */
  font-size: 4rem;
  /* padding-left: 10%; */
}


.content p {
  color: rgb(226, 223, 176);
  font-size: 40px;
  overflow: auto;
  /* padding-left: 10%; */
}

.content hr {
  color:rgb(226, 223, 176); 
  border-width: 3px;
  max-width: 95%;
  border-radius: 5px;
}

.footer {
  /* background-color: rgb(52, 71, 49); */
  justify-content: center;
  font-size: 30px;
}

.li {
  display: inline;
}

.navbar {
  background-color: rgb(52, 71, 49);
  justify-content: center;
  /* padding-left: 20%;
  padding-right: 20%; */
  font-size: 30px;
}

.navbar-brand {
  color:rgb(226, 223, 176);  
  font-size: 30px;
}

.offcanvas {
  /* position: fixed; */
}

.offcanvas-top{
  background-color:rgb(33, 45, 31);
  /* padding-top: 120px */
}

@media (max-width: 979px) {
  body {
    padding-top: 0px;
  }
}